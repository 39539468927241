<template>
  <!-- Add modal here -->
  <div class="created-account-modal">
    <AppModal
      v-if="showModal"
      app-card-classes="modal-card"
      :value="showModal"
      :dismissible="true"
      :persistent="true"
    >
      <template>
        <AppCardImageTitle
          key="success"
          class="created-account"
          title="Account created"
          alt="Account created"
          src="illustrations/created.svg"
        >
          <p class="text-gray type-subtitle">
            You have limited access to your account while it’s being reviewed. In order to start
            sending money, you need to complete your pending actions
          </p>
        </AppCardImageTitle>

        <AppButton type="submit" class="w-full mt-12" @click="goToPendingActions">
          Continue
        </AppButton>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { useRouter } from '@/composables/useRouter'

import AppCardImageTitle from '@/components/AppCardImageTitle/AppCardImageTitle'
import AppModal from '@/components/AppModal/AppModal'
import AppButton from '@/components/AppButton/AppButton'

export default {
  components: {
    AppCardImageTitle,
    AppModal,
    AppButton,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const router = useRouter()

    const goToPendingActions = () => router.push({ name: 'PendingActions' })

    return {
      goToPendingActions,
    }
  },
}
</script>

<style lang="postcss" scoped>
.modal-card {
  @apply bg-dark;
}
</style>
